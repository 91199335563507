import React from 'react'
import PropTypes from 'prop-types'

const Lock = ({
  className,
  height,
  width,
}) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} className={`bi bi-lock ${className}`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M11.5 8h-7a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-7-1a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-7zm0-3a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"/>
    </svg>
  );
}

Lock.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

Lock.defaultProps = {
  className: null,
  height: 16,
  width: 16,
}

export default React.memo(Lock)
